

import { getExcel,getExcelpost } from '../../api/axios'
import { Loading } from 'element-ui'

export  function exportExcel(url,params){
  const loading = Loading.service({
    lock: true,
    text: '导出中...',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  getExcel(url,params).then((response) => {
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }); // Excel MIME类型
  
    // 创建一个URL指向Blob对象
    const url = window.URL.createObjectURL(blob);
  
    // 创建隐藏的可下载链接
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.download = `${params.exportExcelName||'excel'}.xlsx`; // 自定义下载的Excel文件名
  
    // 触发点击下载
    document.body.appendChild(link);
    link.click();
  
    // 下载完成后清理
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    loading.close()
  }).catch(()=>{
    loading.close()
  })

}

export  function exportExcelPost(url,params){
  const loading = Loading.service({
    lock: true,
    text: '导出中...',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  getExcelpost(url,params).then((res ) => {
    const link = document.createElement("a");
        const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${params.exportExcelName||'excel'}.xlsx`);  //报表名称可自定义
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.close()
  }).catch(()=>{
    loading.close()
  })

}